@import "bootstrap/functions";
@import "variables";
@import "bootstrap/mixins";
@import "markdown.scss"; // Markdown Preview for comment
@import "markdown-body.scss"; // Markdown Preview for Document
@import "component/humberger_menu.scss";
@import "component/sceleton.scss";

$darken-color: 		#232426;
$lighten-color: 	rgba($black, .03) !default;

$active-line-height: 3px;

$navbar-height: 3.5rem; // 56px
$logo-height: 2.35rem;
$navbar-active-border-height: $active-line-height;
$navbar-avatar-width: 2rem;
$dropdown-avatar-width: 2.5rem;

// $sidenav-width: 300px;
$sidenav-width: 17rem;
$sidenav-bg: #1D1F3E;
$sidenav-bg-active: rgba(81, 74, 142, 0.25);
$sidenav-bg-hover: transparent;
$sidenav-item-height: 2.5rem;
$submenu-item-padding-x: $dropdown-item-padding-x * 1.5;
$sidenav-divider-padding-x: false;

$avatar-width-sm: 2rem;
$avatar-width: 2.5rem;
$avatar-width-lg: 4.5rem;

/* ------------------------------------ 
* Scrollbars
* ------------------------------------ */

::-webkit-scrollbar {
	width: 8px;
	height: 8px;
}
/* Track */
::-webkit-scrollbar-track {
	background: #eee;
	border: 1px solid #ddd;
	border-radius: 16px;
}
/* Handle */
::-webkit-scrollbar-thumb {
	background: #888;
	border-radius: 5px;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #777;
}

.sidenav {
    ::-webkit-scrollbar {
        width: 6px;
        height: 6px;
    }

    ::-webkit-scrollbar-track {
        background: $sidenav-bg;
        border-width: 0px;
    }
    ::-webkit-scrollbar-thumb {
        background: #777;
    }
}

/* 
========================================
Utilitas 
======================================== 
*/

// full height
html, body{
	height: 100%;
	position: relative;
}
.bg-darken{
	background: $darken-color;
}
.bg-lighten{
	background: $lighten-color;
}
.flex-full{
	-webkit-flex: 1 1 0;
	-ms-flex: 1;
	flex: 1 1 0;
}
.flex-full{
	@each $breakpoint in map-keys($grid-breakpoints) {
		$next: breakpoint-next($breakpoint, $grid-breakpoints);
		$infix: breakpoint-infix($next, $grid-breakpoints);

		&#{$infix} {
			@include media-breakpoint-up($next) {
				-webkit-flex: 1 1 0;
				-ms-flex: 1;
				flex: 1 1 0;
			}
		}
	}
}
.flex-column{
	.flex-full{
		overflow: auto;
	}
}
.rounded-sm{
	border-radius: $border-radius-sm;
}
.rounded-lg{
	border-radius: $border-radius-lg;
}
.rounded-xl{
	border-radius: $border-radius-xl;
}
a.text-decoration-none:hover {
    text-decoration: none;
}

/* 
========================================
Layout 
======================================== 
*/
.navbar-avatar{
	width: $navbar-avatar-width;
	height: $navbar-avatar-width;
	// background: $base;
	line-height: $navbar-avatar-width;
	text-align: center;
	color: $white;
	margin-top: ($navbar-height - $navbar-avatar-width) / 2;
	border-radius: 50%;
	overflow: hidden;
	img{
		vertical-align: top;
		object-fit: cover;
		width: $navbar-avatar-width;
		height: $navbar-avatar-width;
	}
}
.navbar-avatar-group{
	padding: 0;
	margin: 0;
	list-style: none;
	align-items: center;
	direction: rtl;
    margin-right: .5rem;
    white-space: nowrap;
	li{
        margin-top: ($navbar-height - $navbar-avatar-width) / 2;
		display: inline-flex;
		vertical-align: top;
		margin-right: -.5rem;
		div{
            position: relative;
		}
        .UserAvatar {
            font-size: 0.8em;
        }
		.navbar-avatar{
			direction: ltr;
			margin-top: 0;
			position: relative;
			color: $text-muted;
            background: $gray-300;
		}
	}
}
.navbar{
	min-height: $navbar-height;
	padding: 0;
	z-index: 99;
	.navbar-brand{
		padding-top: 0;
		padding-bottom: 0;
		// padding-bottom: 5px;
		// line-height: $navbar-height;
		img{
			height: $logo-height;
		}
	}
	.auto-height{
		height: $navbar-height;
	}
	.navbar-toggler{
		border-color: transparent;
	}
	.fas, .far, .fa{
		font-size: 1rem;
	}
	.navbar-nav .nav-item{
		.dropdown-shadow{
			box-shadow: $box-shadow-sm;
		}
		.dropdown-item{
			&.active, &.active:hover{
				color: $primary;
			}
			&:focus{
				@include gradient-bg($dropdown-link-hover-bg);
				color: $dropdown-link-hover-color;
			}
		}
	}
	.navbar-nav .nav-item.active .nav-link:after{
		content:" ";
		position: absolute;
		height: $navbar-active-border-height;
		left: 0;
		right: 0;
		bottom: 0;
		background: $primary;
		// border-radius: $navbar-active-border-height $navbar-active-border-height 0 0;
	}
	&.active-border-top{
		.navbar-nav .nav-item.active .nav-link:after{
			bottom: auto;
			top: 0;
			// border-radius: 0 0 $navbar-active-border-height $navbar-active-border-height;
		}
	}
	&.navbar-dark{
		.navbar-nav .nav-item.active .nav-link:after{
			background: $white;
		}
	}
	&.navbar-light{
		.navbar-nav .active .nav-link{
            font-weight: 600;
		}
		.navbar-nav .nav-item.active .nav-link:after{
			background: $darken-color;
		}
	}
	&.bg-white, &.bg-light, &.bg-dark, &.bg-darken{
		.navbar-nav .nav-item.active .nav-link:after{
			background: $primary;
		}
	}
	@mixin navbar-expand() {
		> .container,
		> .container-fluid {
			padding-right: ($grid-gutter-width / 2);
			padding-left: ($grid-gutter-width / 2);
		}
		.navbar-collapse{
			position: absolute;
			left: 0;
			top: 0;
			/*top: 100%;*/
			.navbar-nav{
				position: absolute;
				left: $grid-gutter-width / 2;
				top: $navbar-height;
				flex-direction: column;
				z-index: $zindex-dropdown;
				float: left;
				min-width: $dropdown-min-width;
				padding: $dropdown-padding-y 0;
				/*margin: $dropdown-spacer 0 0; // override default ul*/
				margin: 0; // override default ul
				font-size: $font-size-base; // Redeclare because nesting can cause inheritance issues
				color: $body-color;
				text-align: left; // Ensures proper alignment if parent has it changed (e.g., modal footer)
				list-style: none;
				background-color: $dropdown-bg;
				background-clip: padding-box;
				border: $dropdown-border-width solid $dropdown-border-color;
				/*@include border-radius($dropdown-border-radius);*/
				border-radius: 0 0 $dropdown-border-radius $dropdown-border-radius;
				@include box-shadow($dropdown-box-shadow);
				.nav-divider {
					@include nav-divider($dropdown-divider-bg);
				}
				.nav-item{
					&.active{
						.nav-link{
							color: $primary;
							&:after{
								height: auto;
								top: 0;
								bottom: 0;
								right: auto;
								width: $navbar-active-border-height;
								background: $primary;
								// border-radius: 0 $navbar-active-border-height $navbar-active-border-height 0;
							}
						}
					}
				}
				.nav-link{
					line-height: $sidenav-item-height;
					display: block;
					width: 100%; // For `<button>`s
					padding-right: $dropdown-item-padding-x;
					padding-left: $dropdown-item-padding-x;
					clear: both;
					font-weight: $font-weight-normal;
					color: $dropdown-link-color;
					text-align: inherit; // For `<button>`s
					white-space: nowrap; // prevent links from randomly breaking onto new lines
					background-color: transparent; // For `<button>`s
					border: 0; // For `<button>`s

					@include hover-focus {
						color: $dropdown-link-hover-color;
						text-decoration: none;
						@include gradient-bg($dropdown-link-hover-bg);
					}
				}
				.dropdown-menu{
					border-radius: 0;
					border-width: 0;
					padding-top: .2rem;
					padding-bottom: .5rem;
					box-shadow: none;
					.dropdown-item{
						padding-left: $submenu-item-padding-x;
						position: relative;
						&.active{
							background: transparent;
							color: $primary;
							&:after{
								content:" ";
								position: absolute;
								top: 0;
								bottom: 0;
								left: 0;
								width: $navbar-active-border-height;
								background: $primary;
								// border-radius: 0 $navbar-active-border-height $navbar-active-border-height 0;
							}
							@include hover-focus {
								color: $primary;
								text-decoration: none;
								@include gradient-bg($dropdown-link-hover-bg);
							}
						}
					}
				}
			}
		}
		.navbar-right{
			flex-direction: row;
			.nav-item{
				.nav-link{
					padding-right: $navbar-nav-link-padding-x;
					padding-left: $navbar-nav-link-padding-x;
				}
				.dropdown-menu{
					position: absolute;
				}
			}
		}
	}
	&.navbar-expand{
		@each $breakpoint in map-keys($grid-breakpoints) {
			$next: breakpoint-next($breakpoint, $grid-breakpoints);
			$infix: breakpoint-infix($next, $grid-breakpoints);

			&#{$infix} {
				@include media-breakpoint-down($breakpoint) {
					@include navbar-expand();
				}
			}
		}
		.navbar-toggler{
			display: block;
		}
		.navbar-collapse{
			display: none;
			.navbar-nav{
				left: 0;
			}
		}
		.navbar-collapse.show{
			display: block;
		}
		.navbar-nav{
			.dropdown-menu{
				position: static;
			}
		}
	}
}
.navbar-nav{
	.nav-item{
		.nav-link{
			position: relative;
			padding-top: 0;
			padding-bottom: 0;
			line-height: $navbar-height;
            .badge-dot {
                // Untuk yang menggunakan badge dot style
                // Example #1: <Link className="nav-link" to={path}>{label}<span className="badge-dot"></span></Link>
                // Example #2: 
                // <a className={`nav-link nav-link-icon`} href="/" role="button">
                //     <FontAwesomeIcon icon="bell"/>
                //     <span className="badge-dot"></span>
                // </a>
                width: 8px;
                height: 8px;
                top: 18px;
                display: inline-block;
                margin-left: 3px;
                margin-bottom: 5px;
                right: 0px;
                z-index: 2;
                background: $primary;
                border-radius: 50%;
            }
            .badge {
                // Untuk yang menggunakan badge number style
                line-height: .9rem;
                padding: 0 .2rem;
                font-size: .57rem;
                position: absolute;
                top: 15px;
                left: 17px;
                border-radius: .2rem;
            }
        }
        .nav-link-icon {
            font-size: 1.5em;
            .badge-dot {
                position: absolute;
                margin-right: 7px;
            }
        }
	}
	.dropdown-menu{
		// border-radius: 0 0 $dropdown-border-radius $dropdown-border-radius;
		.dropdown-item{
			position: relative;
			&.active{
				background: transparent;
				color: $primary;
				&:after{
					content:" ";
					position: absolute;
					top: 0;
					bottom: 0;
					left: 0;
					width: $navbar-active-border-height;
					background: $primary;
					// border-radius: 0 $navbar-active-border-height $navbar-active-border-height 0;
				}
				@include hover-focus {
					color: $primary;
					text-decoration: none;
					@include gradient-bg($dropdown-link-hover-bg);
				}
			}
		}
	}
}
.sidenav{
	width: $sidenav-width;
	background-color: $sidenav-bg;
	z-index: 5;
	overflow: auto;
	margin-left: 0;
	transition: all .2s;
	&.minimized{
		margin-left: -1 * $sidenav-width;
	}
	.sidenav-header{
		border-bottom: 1px solid $sidenav-bg-hover;
		.sidenav-avatar{
			text-align: center;
			padding-top: 3rem;
			padding-bottom: .75rem;
			.avatar{
				margin-bottom: .75rem;
				background: $primary;
				margin-left: auto;
				margin-right: auto;
			}
			.sidenav-avatar-text{
				margin-bottom: .75rem;
				font-size: 1rem;
				color: $navbar-dark-hover-color;
			}
		}
		.sidenav-brand{
			margin-left: $dropdown-item-padding-x;
			margin-right: $dropdown-item-padding-x;
			line-height: $navbar-height;
		}
		.sidenav-toggler{
			background: transparent;
			border: none;
			color: $navbar-dark-color;
			font-size: $navbar-brand-font-size;
			padding: 0;
			margin-right: 1rem;
			&:focus{
				outline: none;
			}
		}
	}
	.navbar-nav{
		// padding-top: .75rem;
		padding-bottom: .75rem;
		.nav-divider {
			@include nav-divider($sidenav-bg-active);
			@if $sidenav-divider-padding-x {
				margin-left: $dropdown-item-padding-x;
				margin-right: $dropdown-item-padding-x;
			}
		}
		.nav-item{
			.nav-link{
				line-height: $sidenav-item-height;
				padding-right: $dropdown-item-padding-x;
				padding-left: $dropdown-item-padding-x;
				small{
					color: $navbar-dark-disabled-color;
				}
				@include hover-focus {
					text-decoration: none;
					@include gradient-bg($sidenav-bg-hover);
				}
				.badge{
					float: right;
					margin-top: .75rem;
				}
			}
			&.dropdown{
				.dropdown-menu{
					background: transparent;
					border-width: 0;
					padding-top: .2rem;
					padding-bottom: .5rem;
					@if $sidenav-divider-padding-x {
						.dropdown-divider{
							margin-left: $submenu-item-padding-x;
							margin-right: $dropdown-item-padding-x;
						}
					}
					.dropdown-item{
						// padding-left: $submenu-item-padding-x;
						padding-left: 3.4rem;
						color: $navbar-dark-color;
						@include hover-focus {
							color: $navbar-dark-hover-color;
							text-decoration: none;
							@include gradient-bg($sidenav-bg-hover);
						}
						&.active{
							color: $navbar-dark-active-color;
							background: $sidenav-bg-active;
							&:after{
								display: none;
							}
						}
						&:not(.active):focus{
							text-decoration: none;
							@include gradient-bg($sidenav-bg-active);
						}
					}
				}
			}
			&.active{
				.nav-link{
					background: $sidenav-bg-active;
				}
				&.dropdown{
					.dropdown-menu{
						display: block;
					}
				}
			}
		}
	}
}
.nav-divider-text, .dropdown-divider-text{
	font-size: 12px;
	color: $gray-600;
	padding-right: $dropdown-item-padding-x;
}
.nav-divider-text{
	margin-top: .5rem;
	margin-bottom: .5rem;
	padding-left: $dropdown-item-padding-x;
}
.navbar-dark{
	.nav-divider-text, .dropdown-divider-text{
		color: rgb(97, 99, 134);
	}
}
.max-container{
	@each $breakpoint in map-keys($container-max-widths) {
		&-#{$breakpoint} {
			max-width: map-get($container-max-widths, $breakpoint);
		}
	}
}
body.navbar-fixed-top{
	padding-top: $navbar-height;
}
.content{
	padding-top: 1rem;
	padding-bottom: 1rem;
	.content-header{
		padding-top: 1rem;
		padding-bottom: 1.25rem;
		.content-title{
			margin-bottom: .25rem;
		}
	}
}

@mixin make-row-custom($gutter) {
	margin-right: $gutter/-2;
	margin-left: $gutter/-2;

	> .col,
	> [class*="col-"] {
		padding-right: $gutter/2;
		padding-left: $gutter/2;
	}
}
@each $size, $length in $spacers {
	.row-gutter-#{$size} {
		@include make-row-custom($length);
	}
}

/* 
========================================
Component 
======================================== 
*/
.form-search{
	border-radius: $btn-border-radius-lg;
    position: relative;
    min-width: 150px;
    margin-left: 20px;
	.form-control{
		height: 2rem;
		padding-left: 1rem;
		padding-left: 1rem;
	}
	.search-label{
		position: absolute;
		right: 1rem;
		top: .35rem;
		color: $text-muted;
	}
}
.badge{
	border-radius: 1rem;
	line-height: 1rem;
	padding: 0 .325rem;
}
.badge-text{
	line-height: 1rem;
	vertical-align: text-bottom;
}
.tag{
	border: 1px solid $border-color;
	line-height: 1rem;
	padding: 0 .6rem;
	border-radius: 3px;
    color: $text-muted;
    &:hover {
        a.text-muted {
            opacity: 1;
        }
    }
    .text-muted {
        opacity: 0.4;
    }
}
.dropdown-menu{
	overflow: hidden;
	.media-body{
		line-height: 1.25;
		white-space: pre-wrap;
	}
	.dropdown-body{
		max-height: 20rem;
		overflow: auto;
	}
}
.table{
	tr{
		td, th{
			&:first-child{
				padding-left: 1rem;
			}
			&:last-child{
				padding-right: 1rem;
			}
		}
	}
}
.card{
	.card-title, p, ol{
		margin-bottom: $card-spacer-y;
	}

	img{
      max-width: 100%;
	}
	
	.card-header{
		.card-title{
			margin-bottom: 0;
		}
	}
	.card-body{
		padding-top: $card-spacer-y;
		padding-bottom: $card-spacer-y;
	}
	.card-footer{
		padding-top: $card-spacer-y / 3 * 2;
		padding-bottom: $card-spacer-y / 3 * 2;
	}
	.card-body{
		> .form-group:last-child{
			margin-bottom: 0;
		}
	}
	.form-group{
		margin-bottom: $card-spacer-y;
	}
	hr{
		border-color: $border-color;
		margin-top: $card-spacer-y;
		margin-bottom: $card-spacer-y;
	}
	> .alert{
		margin-bottom: 0;
		padding: .5rem $card-spacer-x;
		border-radius: 0;
	}
	> .table{
		thead{
			tr{
				th{
					border-top: 0;
				}
			}
		}
	}
	.table{
		&:last-child{
			margin-bottom: 0;
		}
		tr{
			td, th{
				&:first-child{
					padding-left: $card-spacer-x;
				}
				&:last-child{
					padding-right: $card-spacer-x;
				}
			}
		}
	}
	.card-header-tabs{
		margin-left: 0;
		margin-right: 0;
	}
}

.custom-select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

@mixin make-avatar($width) {
	width: $width;
	height: $width;
	border-radius: 50%;
	overflow: hidden;
	text-align: center;
	// background: $border-color;
	img{
		width: $width;
		height: $width;
		object-fit: cover;
		vertical-align: top;
	}
	span{
		line-height: $width;
	}
	svg{
		vertical-align: middle;
    	height: $width;
	}
}
.avatar{
	@include make-avatar($avatar-width);
}
.avatar-sm{
	@include make-avatar($avatar-width-sm);
}
.avatar-lg{
	@include make-avatar($avatar-width-lg);
}
.list-hover{
	.list-group-item{
		&:hover{
			background: $lighten-color;
		} 
		&.bg-active{
			background: $soft;
		}
	}
}
.timeline{
	position: relative;
	&:before{
		content: " ";
		position: absolute;
		border-left: 1px solid $border-color;
		height: 100%;
		left: 4.75rem;
		top: -1rem;
	}
	.avatar{
		z-index: 5;
		background: #eee;
	}
	.rounded-xl{
		> textarea{
			border-radius: $border-radius-xl $border-radius-xl 0 0;
		}
	}
}
.btn, .form-control{
	&.rounded-pill{
		border-radius: 3rem;
	}
}
.show-hover{
	.show-hover-target{
		display: none;
	}
	&:hover, &:focus{
		.show-hover-target{
			display: inline-block;
		}
	}
}
.bg-0{
	background: transparent;
}

::-webkit-color-swatch{
	border: none;
}

.thumb{
	max-width: 100%;
}

.comment{
	.mde-header{
		border-radius: $border-radius-xl $border-radius-xl 0 0;
	}
	.mde-text.form-control{
		border-radius: 0;
	}
	.card-footer{
		border-radius: 0 0 $border-radius-xl $border-radius-xl;
		border-left: $border-width solid $border-color;
		border-right: $border-width solid $border-color;
		border-bottom: $border-width solid $border-color;
		border-top: none;
	}
}

.codex-editor__redactor{
	padding-bottom: 1rem !important;
}

/* ------------------------------------ 
 * List Discussion
 * ------------------------------------ */

.list-discussion {
    .list-group-item .media-body > *:last-child {
       margin-bottom: 0!important;
   }
}

.caret {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 0.255em;
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
    vertical-align: middle;
}

.scroll-onhover {
    overflow: hidden;
    &:hover {
        overflow: overlay;
    }
}

.text-decoration-underline{
	text-decoration: underline;
}

.clickable {
    cursor: pointer;
}


.UserAvatar--dark {
    color: white;
}
.UserAvatar--smaller {
    font-size: 0.8em;
}

@keyframes shake_animation {
    0% {transform: rotate(0deg); color: $secondary}
    2% {transform: rotate(15deg);}
    4% {transform: rotate(-15deg);}
    6% {transform: rotate(15deg);}
    8% {transform: rotate(-15deg);}
    10% {transform: rotate(15deg);}
    12% {transform: rotate(-7deg);}
    14% {transform: rotate(7deg);}
    16% {transform: rotate(0deg); color: inherit}
}

.shake-it {
    display: block;
    animation: shake_animation 5s infinite alternate;
}


.document-container {
    max-width: 740px;
    margin-left: auto;
    margin-right: auto;
}

.document-paper {
    margin: 20px auto;
    padding: 40px;
    box-shadow: 0 0 10px 10px #f1f1f1;
    background-color: white;
    border-radius: 5px;
    h1, h2, h3, h4, h5, h6 {
        &:first-child {
            margin-top: 0
        }
    }
}