@import "variables.scss";

.mde-header {
  flex-shrink: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  // border-bottom: 1px solid $mde-border-color;
  border-radius: $mde-border-radius $mde-border-radius 0 0;
  background: $mde-toolbar-color;
  border-top: 1px solid $mde-border-color;
  border-left: 1px solid $mde-border-color;
  border-right: 1px solid $mde-border-color;

  padding: $padding-y $padding-x;

  .mde-tabs {
    display: flex;
    flex-direction: row;
    margin-right: .75rem;

    button {
      padding: .25rem $btn-padding-y;
      border-radius: $mde-border-radius;
      background-color: transparent;
      border: 1px solid transparent;
      cursor: pointer;
      user-select: none;
      outline: none;
      &.selected {
        background: $soft;
        // border: 1px solid $mde-border-color
      }
    }
  }

  .svg-icon {
    width: 1em;
    height: 1em;
    display: inline-block;
    font-size: inherit;
    overflow: visible;
    // vertical-align: -.125em;
  }

  ul.mde-header-group {
    margin-right: .75rem;
    margin-bottom: 0;
    padding: .25rem 0;
    // padding: $mde-toolbar-padding;
    list-style: none;
    display: flex;
    flex-wrap: nowrap;

    &.hidden {
      visibility: hidden;
    }

    li.mde-header-item {
      display: inline-block;
      position: relative;
      // margin: 0 4px;
      button {
        text-align: left;
        cursor: pointer;
        // height: 22px;
        padding: 0 .25rem;
        margin: 0;
        border: none;
        background: none;
        color: $mde-button-color;
        @keyframes tooltip-appear {
          from {
            opacity: 0;
          }
          to {
            opacity: 1;
          }
        }
        @mixin tooltip-animation {
          animation-name: tooltip-appear;
          animation-duration: 0.2s;
          animation-delay: 0.5s;
          animation-fill-mode: forwards;
        }
        &.tooltipped {
          &:hover::before {
            @include tooltip-animation();
            opacity: 0;
            position: absolute;
            z-index: 1000001;
            width: 0;
            height: 0;
            color: rgba(0, 0, 0, 0.8);
            pointer-events: none;
            content: "";
            border: 5px solid transparent;
            top: -5px;
            right: 50%;
            bottom: auto;
            margin-right: -5px;
            border-top-color: rgba(0, 0, 0, 0.8);
          }
          &:hover::after {
            @include tooltip-animation();
            font-size: 11px;
            opacity: 0;
            position: absolute;
            z-index: 1000000;
            padding: 5px 8px;
            color: #fff;
            pointer-events: none;
            content: attr(aria-label);
            background: rgba(0, 0, 0, 0.8);
            border-radius: 3px;
            right: 50%;
            bottom: 100%;
            transform: translateX(50%);
            margin-bottom: 5px;
            white-space: nowrap;
          }
        }
      }
    }
  }
}

