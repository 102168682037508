@import "variables";

ul.mde-suggestions {
  position: absolute;
  z-index: 99;
  min-width: 180px;
  padding: $dropdown-padding-y 0;
  margin: 20px 0 0;
  list-style: none;
  cursor: pointer;
  background: #fff;
  border: 1px solid $mde-border-color;
  border-radius: $mde-border-radius;
  // box-shadow: 0 1px 5px rgba(27, 31, 35, .15);
  max-height: 180px;
  overflow-y: auto;

  li {
    padding: $dropdown-item-padding-y $dropdown-item-padding-x;

    &:hover {
      background-color: $dropdown-link-hover-bg;
    }
    &[aria-selected=true] {
      color: $mde-white-color;
      background-color: $primary;
    }
  }
}
