//General
@import "../../../scss/bootstrap/functions";
@import "../../../scss/variables";
@import "../../../scss/bootstrap/mixins";


$mde-border-radius:                 $border-radius;
$mde-white-color:                   white !default;
$mde-border-color:                  $input-border-color;

$mde-button-color:                  #242729 !default;
$mde-toolbar-color:                 white !default;
$mde-selected-color:                $primary !default;
$mde-toolbar-padding:               10px !default;

$mde-editor-default-min-height:     200px !default;
$mde-editor-padding:                10px !default;

$mde-preview-horizontal-padding:    10px !default;
$mde-preview-padding:               $input-padding-y $input-padding-x;

$mde-preview-default-min-height:    $mde-editor-default-min-height !default;
$mde-preview-default-height:        auto !default;


// custom 

$padding-y: $input-padding-y;
$padding-x: $input-padding-x;


$btn-padding-y: $input-btn-padding-y-sm !default;
$btn-padding-x: $input-btn-padding-x-sm !default;