@import "variables.scss";

.mde-textarea-wrapper {
  position: relative;

  textarea.mde-text {
    border-radius: 0 0 $mde-border-radius $mde-border-radius;
  //   width: 100%;
  //   border: 0;
  //   padding: $mde-editor-padding;
  //   vertical-align: top;
    resize: none;
    overflow-y: auto !important;
  }
}
