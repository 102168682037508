
@keyframes loading {
  0% {
    transform: translateX(0);
  }
  50%,
  100% {
    transform: translateX(460px);
  }
}

.sceleton {
    user-select: none;
    color: transparent;
    position: relative;
    background: #f2f2f2;
    overflow: hidden;
    display: flex;
    line-height: 1.5;
    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 30px;
        height: 100%;
        background: linear-gradient(to right, #f2f2f2, #ddd, #f2f2f2);
        animation: loading 2s infinite linear;
    }
}