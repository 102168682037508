
.hamburger_svg {
  color:#333;
  // transition: 1s;
  path {
    animation: maximize_animation .5s ease;
    &:nth-child(odd) {
      animation: maximize_mini_animation .5s ease;
    }
  }
  &.minimized {
    // color: red;
    path {
    //   transform: translateX(-2px);
      animation: minimize_animation .5s ease;
      &:nth-child(odd) {
        transform: translateX(-10px);
        animation: minimize_mini_animation .5s ease;
      }
    }
  }
}

@keyframes minimize_animation {
  0% {
    transform: translateX(0);
  }
  30% {
    transform: translateX(-16px);
  }
  100% {
    transform: translateX(-5px);
  }
}

@keyframes minimize_mini_animation {
  0% {
    transform: translateX(0);
  }
  30% {
    transform: translateX(-16px);
  }
  100% {
    transform: translateX(-10px);
  }
}

@keyframes maximize_animation {
  0% {
    transform: translateX(-5px);
  }
  30% {
    transform: translateX(-16px);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes maximize_mini_animation {
  0% {
    transform: translateX(-15px);
  }
  30% {
    transform: translateX(-16px);
  }
  100% {
    transform: translateX(0);
  }
}
